import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { createFragmentContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import classNames from 'classnames'
import { withRouter, Link } from 'react-router-dom'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import * as Sentry from '@sentry/browser'

import { withStyles, withTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import Collapse from '@material-ui/core/Collapse'
import deepOrange from '@material-ui/core/colors/deepOrange'

import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import GroupIcon from '@material-ui/icons/Group'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import RedirectIcon from '@material-ui/icons/Loop'
import SEOIcon from '@material-ui/icons/ImageSearch'
import PaymentIcon from '@material-ui/icons/Payment'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import SettingsIcon from '@material-ui/icons/Settings'
import LogsIcon from '@material-ui/icons/FormatListBulleted'
import ChatIcon from '@material-ui/icons/ChatOutlined'
import ChatConfigurationIcon from '@material-ui/icons/ContactSupport'
import PromoIcon from '@material-ui/icons/VolumeUp'
import GiveawayIcon from '@material-ui/icons/Redeem'
import ThemesIcon from '@material-ui/icons/FormatColorFill'
import WebsiteIcon from '@material-ui/icons/Language'

import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery'
import Hidden from '@material-ui/core/Hidden'

const __DEV__ = process.env.NODE_ENV === 'development'

const logout = () => {
    localStorage.removeItem('token')
    sessionStorage.clear()
    document.cookie = document.cookie.replace(/token=.*;/, '')
    window.location.replace('/')
}

const sections = [
    {
        label: 'Website',
        name: 'website',
        icon: <WebsiteIcon />,
        sections: [
            {
                label: 'Themes schedule',
                name: 'themes',
                icon: <ThemesIcon />,
            },
        ],
    },
    {
        label: 'Users',
        name: 'users',
        icon: <GroupIcon />,
    },
    {
        label: 'SEO',
        name: 'SEO',
        icon: <SEOIcon />,
        sections: [
            {
                label: 'Redirects',
                name: 'redirects',
                icon: <RedirectIcon />,
            },
        ],
    },
    {
        label: 'Payments',
        name: 'payments',
        icon: <PaymentIcon />,
        sections: [
            {
                label: 'Configuration',
                name: 'payments',
                icon: <SettingsIcon />,
            },
            {
                label: 'Logs',
                name: 'payments/logs',
                icon: <LogsIcon />,
            },
        ],
    },
    {
        label: 'Chat',
        name: 'chat/blacklisted-phrases',
        icon: <ChatIcon />,
        sections: [
            {
                label: 'Blacklisted words',
                name: 'chat/blacklisted-phrases',
                icon: <ChatConfigurationIcon />,
            },
        ],
    },
    {
        label: 'Rewards',
        name: 'rewards',
        icon: <GiveawayIcon />,
        sections: [
            {
                label: 'Promotions',
                name: 'rewards/promo',
                icon: <PromoIcon />,
            },
            {
                label: 'Giveaways',
                name: 'rewards/giveaways',
                icon: <GiveawayIcon />,
            },
        ],
    },
]

const Menu = withTheme()(({ isOpen, onToggle, classes, theme, ...props }) => {
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    return (
        <Drawer
            {...props}
            onClose={onToggle}
            anchor={!isDesktop ? 'top' : 'left'}
            variant={isDesktop ? 'permanent' : 'temporary'}
            classes={
                isDesktop
                    ? {
                          paper: classNames(
                              classes.drawerPaper,
                              !isOpen && classes.drawerPaperClose
                          ),
                      }
                    : {}
            }
            open={isOpen}
        />
    )
})

class MainApp extends PureComponent {
    state = {
        isOpen: false,
        sections: {},
    }

    user = get(this.props, 'userManagement.currentUser')

    handleToggleDrawer = () => {
        this.setState(state => ({ isOpen: !state.isOpen }))
    }

    toggleSection = name => () => {
        this.setState(state => ({
            sections: {
                ...state.sections,
                [name]: {
                    isOpen: !get(state.sections[name], 'isOpen'),
                },
            },
        }))
    }

    openModule = name => () => {
        this.props.history.push(`/${name}`)
    }

    isSectionOpen = name => get(this.state.sections[name], 'isOpen', false)

    componentDidCatch(error, errorInfo) {
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key])
            })
            Sentry.captureException(error)
        })
    }

    render() {
        const { classes, theme } = this.props
        const { isOpen } = this.state

        return (
            <div className={classes.root}>
                <AppBar
                    position="absolute"
                    className={classNames(
                        classes.appBar,
                        isOpen && classes.appBarShift
                    )}
                >
                    <Toolbar disableGutters={!this.state.isOpen}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleToggleDrawer}
                            className={classNames(
                                classes.menuButton,
                                isOpen && classes.hide
                            )}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Hidden mdUp>
                            <div className={classes.flex} />
                        </Hidden>

                        <Hidden smDown>
                            <Link
                                to="/"
                                className={classNames(
                                    classes.link,
                                    classes.flex
                                )}
                            >
                                <Typography variant="h6" color="inherit" noWrap>
                                    {
                                        (__DEV__ ? process.env : window)
                                            .REACT_APP_APP_NAME
                                    }{' '}
                                    admin
                                </Typography>
                            </Link>
                        </Hidden>
                        <div className={classes.userProfile}>
                            {this.user.name}
                            <Avatar
                                src={this.user.avatarUrl}
                                className={classes.avatar}
                            >
                                {this.user.name.charAt(0).toUpperCase()}
                            </Avatar>
                        </div>
                        <Button
                            color="inherit"
                            className={classes.toolbarActions}
                            onClick={logout}
                        >
                            Logout
                            <ExitToAppIcon />
                        </Button>
                    </Toolbar>
                </AppBar>
                <Menu
                    onToggle={this.handleToggleDrawer}
                    classes={classes}
                    isOpen={isOpen}
                >
                    <div className={classes.toolbar}>
                        <IconButton onClick={this.handleToggleDrawer}>
                            {theme.direction === 'rtl' ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton>
                    </div>
                    <List>
                        {sections.map(({ name, label, icon, sections }) => (
                            <Fragment key={name}>
                                <ListItem
                                    button
                                    onClick={
                                        isArray(sections)
                                            ? this.toggleSection(name)
                                            : this.openModule(name)
                                    }
                                    key={name}
                                >
                                    <ListItemIcon>{icon}</ListItemIcon>
                                    <ListItemText primary={label} />
                                    {isArray(sections) &&
                                        (this.isSectionOpen(name) ? (
                                            <ExpandLess />
                                        ) : (
                                            <ExpandMore />
                                        ))}
                                </ListItem>
                                {isArray(sections) && (
                                    <Collapse
                                        in={this.isSectionOpen(name)}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List component="div" disablePadding>
                                            {sections.map(
                                                ({ icon, name, label }) => (
                                                    <ListItem
                                                        key={name}
                                                        button
                                                        className={
                                                            classes.nested
                                                        }
                                                        onClick={this.openModule(
                                                            name
                                                        )}
                                                    >
                                                        <ListItemIcon>
                                                            {icon}
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            inset
                                                            primary={label}
                                                        />
                                                    </ListItem>
                                                )
                                            )}
                                        </List>
                                    </Collapse>
                                )}
                            </Fragment>
                        ))}
                    </List>
                </Menu>
                <main className={classes.content}>{this.props.children}</main>
            </div>
        )
    }
}

MainApp.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

const drawerWidth = 240

const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        overflow: 'auto',
        position: 'relative',
        display: 'flex',
        height: '100%',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        height: '-webkit-fill-available',
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 10,
        width: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    link: {
        textDecoration: 'none',
        color: 'white',
    },
    flex: {
        flex: 1,
    },
    toolbarActions: {
        margin: '0 15px',
    },
    avatar: {
        marginLeft: 15,
        backgroundColor: deepOrange[500],
    },
    userProfile: {
        display: 'flex',
        alignItems: 'center',
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4,
    },
})

export default createFragmentContainer(
    withRouter(withStyles(styles, { withTheme: true })(MainApp)),
    {
        userManagement: graphql`
            fragment MainApp_userManagement on UserManagement {
                currentUser {
                    name
                    avatarUrl
                }
            }
        `,
    }
)
